.inner-circle-container {
  @apply w-auto;
}

.social-links-container {
  @apply flex space-x-3 mt-1;
}

.inner-circle {
  @apply fill-red-500 w-[20px] h-[20px];
}

.inner-circle-outline {
  @apply pt-[4px] pl-[3px] w-[28px] h-[28px] border-[1px] border-gray-500 rounded-full hover:border-blue-500 hover:dark:border-blue-500;
}
